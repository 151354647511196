
import { defineComponent, ref } from '@vue/composition-api';

const bulletPoints = [
  '1000+ on-demand boxing, kickboxing, strength, core & recovery workouts (more added weekly)',
  'World-class instructors',
  'Real-time progress tracking',
  'Programs with day-by-day workout plans, and more',
];

export default defineComponent({
  name: 'MembershipInfo',
  setup() {
    const isActive = ref(false);
    function toggleActive() {
      isActive.value = !isActive.value;
    }
    return { isActive, toggleActive, bulletPoints };
  },
});
